<template>
    <div>
        <div class="suumary-title">
            <div>加值功能總計 </div>
            <!-- <div>
                <el-button type="warning">下載本頁</el-button>
            </div> -->
        </div>
        <DescriptionsTemplate :descriptionList="descriptionList"
                              :setUpBtn="false"
                              :descriptionWidth="'950px'">
            <template v-slot:date>
                <el-date-picker v-model="summaryDate"
                                type="daterange"
                                @change="calendarChange"
                                start-placeholder="開始日期"
                                end-placeholder="結束日期">
                </el-date-picker>
            </template>
            <template v-for="item in dataList"
                      v-slot:[item.source_id]
                      :key="item.source_id">
                <el-row :gutter="20">
                    <el-col :span="4">
                        使用次數:{{item.usage_count}}
                    </el-col>
                    <el-col :span="4">
                        免費額度:{{item.free_quota}}
                    </el-col>
                    <el-col :span="16">
                        單功能價格總計:{{item.currency}} {{item.amount}}
                    </el-col>
                </el-row>

            </template>
            <template v-slot:total>{{total}}</template>
        </DescriptionsTemplate>
    </div>
</template>

<script>
import DescriptionsTemplate from '../../../../../components/DescriptionsTemplate.vue'
import { valueAddedSummary } from '../../../../../api/index'
import { dateFormat } from '@/utils/dateFormat.js'
export default {
    components: { DescriptionsTemplate },
    data() {
        return {
            userId: '',
            descriptionList: [
                {
                    label: '日期區間',
                    key: 'date',
                    type: 'SLOT',
                },
            ],
            dataList: [],
            total: '',
            summaryDate: '',
        }
    },
    mounted() {
        if (typeof this.$route.query.id == 'undefined') {
            this.$router.push({ path: 'commercialList' })
        } else {
            this.userId = this.$route.query.id
            // this.dateSet()
            this.getData()
        }
    },
    methods: {
        getData() {
            let startDay = ''
            let endDay = ''
            if (this.summaryDate) {
                startDay = dateFormat(this.summaryDate[0])
                endDay = dateFormat(this.summaryDate[1])
            }
            valueAddedSummary({
                merchant_id: this.userId,
                created_at: startDay,
                created_end: endDay,
            })
                .then((res) => {
                    if (res.code == 300) {
                        this.dataList = res.data.data_list

                        for (let i = 0; i < this.dataList.length; i++) {
                            this.descriptionList.push({ label: this.dataList[i].source_name, key: this.dataList[i].source_id, type: 'SLOT' })
                        }
                        this.descriptionList.push({ label: '價格總計', key: 'total', type: 'SLOT' })
                        this.total = res.data.total_amount
                    }
                    if (res.code == 500) {
                        this.$message.error(res.message)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        //先給初始時間(上個月第一天到上個月最後一天)
        dateSet() {
            let nowdays = new Date(),
                y = nowdays.getFullYear(),
                m = nowdays.getMonth()
            if (m == 0) {
                m = 12
                y = y - 1
            }
            if (m < 10) {
                m = '0' + m
            }
            let myDate = new Date(y, m, 0)
            let startDate = new Date(y, m - 1, 1)
            let endDate = new Date(y, m - 1, myDate.getDate())
            this.summaryDate = [startDate, endDate]
        },
        calendarChange() {
            this.descriptionList = [
                {
                    label: '日期區間',
                    key: 'date',
                    type: 'SLOT',
                },
            ]
            this.getData()
        },
    },
}
</script>

<style lang="scss" scoped>
.suumary-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>