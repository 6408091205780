<template>
    <div>
        <DescriptionsTemplate :descriptionList="descriptionList"
                              :deafulForm="deafulForm"
                              :setUpBtn="true"
                              @open-dialog="openDialog">
        </DescriptionsTemplate>
        <el-form :inline="true"
                 class="ipRobot-form"
                 :model="formInline">
            <el-form-item label="目前區間:">
                <el-date-picker v-model="formInline.date"
                                type="daterange"
                                @change="calendarChange"
                                start-placeholder="開始日期"
                                end-placeholder="結束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary"
                           @click="handleSearch">查詢</el-button>
            </el-form-item>
        </el-form>
        <TableTemplate :colums="colums"
                       v-loading="listLoading"
                       :data="tableData"
                       :paginationShow="true"
                       :pageIndex="pageIndex"
                       :pageCount="pageCount"
                       @change-page="changePage"></TableTemplate>
        <!-- 編輯彈跳視窗-->
        <el-dialog v-model="isShow"
                   :title="'編輯'+serviceLabel"
                   width="40%"
                   destroy-on-close
                   center>
            <FormTemplate :formParams="editForm"
                          @reset-form="isShow=false"
                          btnText2="送出"
                          :deafulcomForm="deafuleditForm"
                          :loading="loading"
                          @on-submit="handleSubmit">
                <template #chat_ids="chat_ids">
                    <div v-for=" (item,index) in chat_ids.comForm.chat_ids"
                         :key="index"
                         class="contact">
                        <el-select v-model="item.chat_id"
                                   filterable
                                   default-first-option
                                   allow-create
                                   placeholder="請輸入群組ID">
                            <el-option v-for="item in optionsChatId"
                                       :key="item.chat_id"
                                       :label="item.chat_id.toString()"
                                       :value="item.chat_id">
                            </el-option>
                        </el-select>
                        <el-select v-model="item.name"
                                   class="select-input"
                                   filterable
                                   default-first-option
                                   allow-create
                                   placeholder="請輸入群組名稱">
                            <el-option v-for="item in optionsChatId"
                                       :key="item.name"
                                       :label="item.name"
                                       :value="item.name">
                            </el-option>
                        </el-select>
                        <!-- <el-button v-if="index==0"
                                   icon="el-icon-circle-plus-outline"
                                   size="mini"
                                   @click="addChatId"
                                   circle></el-button>
                        <el-button v-else
                                   icon="el-icon-remove-outline"
                                   size="mini"
                                   type="danger"
                                   @click="removeChatId(index)"
                                   circle></el-button> -->
                    </div>
                </template>

            </FormTemplate>
        </el-dialog>
        <!-- 編輯確認彈跳視窗-->
    </div>
</template>

<script>
import DescriptionsTemplate from '../../../../../components/DescriptionsTemplate.vue'
import FormTemplate from '../../../../../components/FormTemplate.vue'
import TableTemplate from '../../../../../components/TableTemplate.vue'
import { valueAdded, valueAddedUpdate, valueAddedHistory, valueAddedGroupSearch } from '../../../../../api/index'
import { dateFormat } from '@/utils/dateFormat.js'
export default {
    props: {
        serviceId: {
            type: Number,
            default: 1,
        },
        serviceLabel: {
            type: String,
            default: 'IP加白',
        },
    },
    components: {
        DescriptionsTemplate,
        FormTemplate,
        TableTemplate,
    },
    data() {
        return {
            userId: '',
            descriptionList: [
                //描述表資訊
                {
                    label: 'ID',
                    key: 'id',
                    type: 'TEXT',
                },
                {
                    label: '機器人Token',
                    key: 'tgbot_token',
                    type: 'TEXT',
                },
                {
                    label: '群組ID&名稱',
                    key: 'chat_ids',
                    type: 'List',
                },
                {
                    label: 'API單次價格',
                    key: 'amount',
                    type: 'TEXT',
                },
                {
                    label: '剩餘免費次數',
                    key: 'free_quota',
                    type: 'TEXT',
                },
            ],
            deafulForm: {}, //描述表資訊
            formInline: { date: '' }, //日期
            loading: false,
            isShow: false, //判斷談窗
            editForm: {
                labelWidth: '150px',
                formList: [
                    {
                        label: '機器人Token',
                        key: 'tgbot_token',
                        type: 'INPUT',
                        placeholder: '請輸入機器人Token',
                        span: 24,
                    },
                    {
                        label: '群組ID&名稱',
                        key: 'chat_ids',
                        type: 'SLOT',
                        lists: [],
                        span: 24,
                    },
                    {
                        label: '計次價錢',
                        key: 'amount',
                        type: 'INPUT',
                        placeholder: '請輸入計次價錢',
                        span: 24,
                    },
                    {
                        label: '免費次數',
                        key: 'free_quota',
                        type: 'INPUT',
                        placeholder: '請輸入免費次數',
                        span: 24,
                    },
                ],
            },
            deafuleditForm: { chat_ids: [{ chat_id: '', name: '' }] }, //多選出次驗證要先給空數組
            colums: [
                {
                    name: '使用日期',
                    prop: 'created_at',
                },
                {
                    name: '原始幣別',
                    prop: 'origin_currency',
                },
                {
                    name: '原始金額',
                    prop: 'origin_amount',
                },
                {
                    name: '換算幣別',
                    prop: 'currency',
                },
                {
                    name: '換算金額',
                    prop: 'amount',
                },
                {
                    name: '備註',
                    prop: 'note',
                },
            ],
            tableData: [],
            startDay: '',
            endDay: '',
            listLoading: false,
            pageIndex: 1, //第幾頁
            pageCount: 10, //頁數
            optionsChatId: [],
        }
    },
    mounted() {
        if (typeof this.$route.query.id == 'undefined') {
            this.$router.push({ path: 'commercialList' })
        } else {
            this.userId = this.$route.query.id
            this.getData()
            this.timeSet()
            this.getValueAddedHistory()
        }
    },
    methods: {
        //拿取加值服務資料
        getData() {
            valueAdded({
                merchant_id: this.userId,
                source_id: this.serviceId,
            })
                .then((res) => {
                    if (res.code == 300) {
                        this.deafulForm = { ...res.data.data_info }
                        if (typeof res.data.data_info.id != 'undefined') {
                            this.getValueAddedGroupSearch(res.data.data_info.id)
                        }
                    }
                    if (res.code == 500) {
                        this.$message.error(res.message)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        //歷史資料
        getValueAddedHistory() {
            this.listLoading = true
            if (this.formInline.date) {
                this.startDay = dateFormat(this.formInline.date[0])
                this.endDay = dateFormat(this.formInline.date[1])
            }
            valueAddedHistory({
                merchant_id: this.userId,
                source_id: this.serviceId,
                created_at: this.startDay,
                created_end: this.endDay,
                page: this.pageIndex,
            })
                .then((res) => {
                    if (res.code == 300) {
                        this.pageCount = res.data.total_page
                        this.tableData = res.data.data_list
                        if (this.tableData.length > 0) {
                            this.tableData.push({ created_at: '加總', note: res.data.total_amount_data_info.amount })
                        }
                    }
                    if (res.code == 500) {
                        this.$message.error(res.message)
                    }
                    this.listLoading = false
                })
                .catch((err) => {
                    this.listLoading = false
                    console.log(err)
                })
        },
        //查詢
        handleSearch() {
            this.listLoading = true
            this.pageIndex = 1
            this.getValueAddedHistory()
        },
        //群組查詢
        getValueAddedGroupSearch(id) {
            valueAddedGroupSearch({
                value_added_id: id,
            })
                .then((res) => {
                    if (res.code == 300) {
                        this.optionsChatId = res.data.data_list
                    }
                    if (res.code == 500) {
                        this.$message.error(res.message)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        //先給初始時間(本月第一天到目前日期)
        timeSet() {
            let date = new Date(),
                y = date.getFullYear(),
                m = date.getMonth()
            this.formInline.date = [new Date(y, m, 1), new Date()]
        },
        //判斷選取的時間區間有沒有超過
        calendarChange(el) {
            if (this.DateMinus(el[0], el[1]) > 62) {
                this.$message.error('天數大於2個月')
                this.timeSet()
            }
        },
        //計算日期相減天數
        DateMinus(day1, day2) {
            let difference = Math.abs(day2 - day1)

            return difference / (1000 * 3600 * 24)
        },
        //編輯確認
        handleSubmit(form) {
            let data = {
                merchant_id: this.userId,
                source_id: this.serviceId,
                tgbot_token: form.tgbot_token,
                chat_ids: form.chat_ids,
                amount: form.amount,
                free_quota: form.free_quota,
            }

            this.$confirm('確定要送出？', '提示', {
                type: 'warning',
            })
                .then(() => {
                    valueAddedUpdate(data).then((res) => {
                        if (res.code == 200) {
                            this.$message.success(res.message)
                            this.getData()
                            this.isShow = false
                        } else {
                            this.$message.error(res.message)
                        }
                    })
                })
                .catch(() => {})
        },
        //編輯彈跳窗
        openDialog() {
            this.isShow = true
            this.deafuleditForm = JSON.parse(JSON.stringify(this.deafulForm)) //深拷貝解法
            if (typeof this.deafuleditForm.chat_ids == 'undefined' || this.deafuleditForm.chat_ids.length < 1) {
                this.deafuleditForm.chat_ids = [{ chat_id: '', name: '' }]
            }
        },
        //下一頁
        changePage(val) {
            this.pageIndex = val
            this.getValueAddedHistory()
        },
        //新增其他
        addChatId() {
            this.deafuleditForm.chat_ids.push({ chat_id: '', name: '' })
        },
        //刪除其他
        removeChatId(index) {
            if (index !== -1) {
                this.deafuleditForm.chat_ids.splice(index, 1)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.ipRobot-form {
    padding-top: 20px;
}
.contact {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .el-select {
        flex: 1;
    }
}
.select-input {
    padding-left: 15px;
}
</style>